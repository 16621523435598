<template>
  <div class="modal-wrapper">
    <div class="modal">
      <form @submit.prevent="submit">
        <div class="inputs">
          <input
            type="text"
            class="name"
            placeholder="Name"
            v-model="name"
            minlength="2"
            :disabled="submitting"
            required
          />
          <input
            type="text"
            class="company-name"
            placeholder="Company Name (optional)"
            v-model="companyName"
            :disabled="submitting"
          />
          <label class="contact-method">
            Preferred Contact Method<br />

            <label for="prefer-email"
              ><input
                type="radio"
                id="prefer-email"
                value="email"
                v-model="contactMethod"
                :disabled="submitting"
              />Email</label
            >
            <label for="prefer-phone"
              ><input
                type="radio"
                id="prefer-phone"
                value="phone"
                v-model="contactMethod"
                :disabled="submitting"
              />Phone</label
            >
          </label>
          <input
            type="email"
            class="email"
            placeholder="Email"
            v-model="email"
            :disabled="submitting"
            :required="contactMethod === 'email'"
          />
          <input
            type="tel"
            class="phone"
            placeholder="Phone Number"
            v-model="phoneNumber"
            minlength="10"
            size="10"
            :disabled="submitting"
            :required="contactMethod === 'phone'"
          />
        </div>
        <div class="buttons">
          <img
            src="../assets/icons/spinner.svg"
            class="spinner"
            v-show="submitting"
          />
          <button class="close" @click.prevent="close" :disabled="submitting">
            Go Back
          </button>
          <input type="submit" value="Submit Request" :disabled="submitting" />
        </div>
      </form>
      <div class="message-wrapper">
        <transition name="message">
          <div
            class="message"
            :class="error ? 'error' : ''"
            v-show="error || success"
            v-html="
              error ||
                'Thank you for requesting an order with us. We will get back to you with a quote as soon as possible.'
            "
          ></div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.modal {
  max-width: 500px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

  form {
    .inputs {
      display: grid;
      grid-auto-columns: minmax(200px, 1fr);
      gap: 0 40px;

      @media (max-width: 519px) {
        display: flex;
        flex-direction: column;
      }

      > * {
        margin: auto 0;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    .buttons {
      display: flex;
      margin: 20px 0 0;

      .close {
        margin-left: auto;
      }

      > :not(:last-child) {
        margin-right: 10px;
      }
    }

    .name {
      grid-column: 1;
      grid-row: 1;
    }
    .company-name {
      grid-column: 1;
      grid-row: 2;
    }
    .contact-method {
      grid-column: 1;
      grid-row: 3;
      opacity: 0.8;
      font-size: 15px;
      font-weight: 700;

      @media (max-width: 519px) {
        order: 5;
        margin-bottom: 20px;
        text-align: right;
      }

      label {
        margin-left: 1em;
        font-weight: 500;
      }
    }
    .email {
      grid-column: 2;
      grid-row: 1;

      @media (max-width: 519px) {
        margin-top: 20px;
      }
    }
    .phone {
      grid-column: 2;
      grid-row: 2;
    }

    .spinner {
      position: relative;
      left: -12px;
      bottom: -14px;
      margin: auto 0 auto 0 !important;
      animation: 1.5s spinner linear infinite;
    }
  }

  .message-wrapper {
    height: 0;
  }
  .message {
    box-sizing: border-box;
    position: relative;
    z-index: -1;
    width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 22px 10px 10px;
    border-left: 30px solid #01b700;
    line-height: 1.6;
    color: white;
    background-color: #888899;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

    &.error {
      border-color: #d11;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import * as firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCA6egDFRN7TJpQyAq5iVgj2dcejZwBlGw",
  authDomain: "residency-designer.firebaseapp.com",
  databaseURL: "https://residency-designer.firebaseio.com",
  projectId: "residency-designer",
  storageBucket: "residency-designer.appspot.com",
  messagingSenderId: "23407719452",
  appId: "1:23407719452:web:77e8a6d7702e17cf"
};

firebase.initializeApp(firebaseConfig);

import config from "@/config.js";

export default {
  data() {
    return {
      name: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      contactMethod: "email",
      submitting: false,
      error: null,
      success: false
    };
  },
  computed: {
    ...mapGetters([
      "apparel",
      "color",
      "fabric",
      "quantity",
      "notes",
      "graphics"
    ])
  },

  mounted() {
    if (process.env.NODE_ENV === "development") {
      this.name = "test";
      this.email = "x@x.com";
    }
  },

  methods: {
    async submit() {
      this.submitting = true;
      this.error = null;
      this.success = false;

      const submissionId = this.name + Date.now();
      const directoryRef = firebase
        .storage()
        .ref()
        .child(submissionId);

      const graphicUrls = {};
      try {
        for (const graphic of this.graphics) {
          const fileRef = directoryRef.child(graphic.name);
          const snapshot = await fileRef.put(graphic.file);
          graphicUrls[graphic.name] = await fileRef.getDownloadURL();
        }

        await axios.post(
          `${config.serverUrl}/submit-order`,
          JSON.stringify({
            request: {
              graphics: this.graphics.map(graphic => {
                return {
                  fileName: graphic.fileName,
                  name: graphic.name,
                  side: graphic.side,
                  transform: graphic.transform,
                  downloadUrl: graphicUrls[graphic.name]
                };
              }),
              apparel: this.apparel,
              color: this.color,
              fabric: this.fabric,
              quantity: this.quantity,
              notes: this.notes
            },
            contact: {
              name: this.name,
              companyName: this.companyName,
              email: this.email,
              phoneNumber: this.phoneNumber,
              contactMethod: this.contactMethod
            }
          })
        );

        this.success = true;
      } catch (error) {
        this.error = `Something has gone wrong.<br>Please try again, or contact us directly at <a href="mailto:${config.email}">${config.email}</a>`;
      }

      this.submitting = false;

      return;
    },

    close() {
      this.$emit("close");
    }
  }
};
</script>
